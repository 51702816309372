import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";

var _this = this;

/*
 * @Author: Xiao
 * @Date: 2019-10-27 15:07:03
 * @Description: file content
 */

/* eslint-disable */
export var throwErr = function throwErr(res, h) {
  var message;

  if (res.status === "100002" || res.status === "100012") {
    // message = "无效的sessionId，请重新登入"
    if (res.status === "100002") {
      _Message({
        type: "error",
        message: '用户已在其他地方登录'
      });
    } else {
      _Message({
        type: "error",
        message: '登录已过期'
      });
    }

    var that = _this;

    _MessageBox.alert("登录失效请重新登录", "提示", {
      confirmButtonText: '确定',
      callback: function callback(action) {
        parent.location.href = '/'; //localStorage.clear();

        sessionStorage.clear();
      }
    }); // setTimeout(() => {
    //     Message.closeAll();
    //     // window.location.href = process.env.VUE_APP_BASE_URL + "/#/"
    // }, 1000);

  } else {
    message = res.message || "网络请求发生错误";

    _Message({
      type: "error",
      message: message
    });
  }

  return Promise.reject(message || "网络请求发生错误");
};