import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import home from "@/router/home";
import { getLogOut, getupdatePassword, getupdateUserInfo } from "@/api/login";
import { storageSetItem, storageGetItem } from "@/utils";
import screenfull from "screenfull";

var Base64 = require("js-base64").Base64;

export default {
  name: "LayoutHeader",
  // inject: ["reload"],
  data: function data() {
    return {
      headerIndex: storageGetItem("headerIndex") ? storageGetItem("headerIndex") : 0,
      menuRoute: [],
      menuRouteAside: [],
      dialogVisible: false,
      passwordArry: {},
      dialogUserinformation: false,
      ruleForm: {},
      rules: {
        userName: [{
          required: true,
          message: "请输入用户名称",
          trigger: "blur"
        }],
        userIdCard: [{
          required: true,
          message: "请输入用户身份证",
          trigger: "blur"
        }, {
          pattern: /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|31)|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/,
          message: "身份证格式异常",
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: "请输入用户邮箱",
          trigger: "blur"
        }],
        age: [{
          required: true,
          message: "请输入用户年龄",
          trigger: "blur"
        } // { type: "number", message: "年龄必须为数字值", trigger: "blur" }
        ]
      },
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      // money: localStorage.getItem("money"),
      fullscreen: false
    };
  },
  watch: {
    // 监听头部导航变化
    headerIndex: function headerIndex() {
      storageSetItem("headerFlag", true);
      storageSetItem("asideIndex", 0);
      storageSetItem("headerIndex", this.headerIndex);
      this.onGetMenuRoute(this.headerIndex);
    }
  },
  created: function created() {
    // 初始化头部导航  是否存在用户信息
    if (storageGetItem("userInfo")) {
      // 非管理员
      if (storageGetItem("userInfo").userIdentity != "1") {
        // 本地是否存在头部索引
        this.headerIndex = storageGetItem("headerIndex") === home.length ? 0 : storageGetItem("headerIndex") ? storageGetItem("headerIndex") : 0;
        var newHome = home.slice(2, home.length);
        this.menuRoute = newHome; //console.log(this.menuRoute);
      } else {
        this.headerIndex = storageGetItem("headerIndex") ? storageGetItem("headerIndex") : 0;
        this.menuRoute = _toConsumableArray(home);
      }

      storageSetItem("headerIndex", this.headerIndex);
      this.onGetMenuRoute(this.headerIndex);
    } else {
      // 跳转登录页
      this.$router.push("/");
    }
  },
  methods: {
    /**
     * @Author: Xiao
     * @Descripttion: 刷新
     * @Date: 2020-01-08 09:01:23
     */
    onRefresh: function onRefresh() {
      this.$router.go(0); // this.reload();
    },

    /**
     * @Author: Xiao
     * @Descripttion: 登出
     * @Date: 2020-01-06 17:57:00
     */
    onLogout: function onLogout() {
      var _this = this;

      _MessageBox.confirm("此操作将登出该账号, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // 登出
        getLogOut().then(function () {
          _this.$message({
            type: "success",
            message: "登出成功",
            duration: 1500,
            onClose: function onClose() {
              // localStorage.clear();
              sessionStorage.clear();

              _this.$router.push("/");
            }
          });
        }).catch(function () {
          // localStorage.clear();
          sessionStorage.clear();

          _this.$router.push("/");
        });
      }).catch(function () {
        _this.$message({
          type: "info",
          message: "已取消"
        });
      });
    },

    /**
     * @description: 获取头部导航下菜单
     * @author: Xiao
     * @Date: 2019-12-31 11:21:19
     */
    onGetMenuRoute: function onGetMenuRoute(index) {
      this.menuRouteAside = this.menuRoute[index].children;
      this.$emit("onChangeMenu", this.menuRouteAside);
    },

    /**
     * @description: 头部菜单切换
     * @author: Xiao
     * @Date: 2019-12-31 14:13:11
     */
    onChangeMenu: function onChangeMenu(i) {
      this.headerIndex = i;
    },

    /**
     * @description: 修改密码
     * @author: Xiao
     * @Date: 2019-12-31 14:13:11
     */
    changePassword: function changePassword() {
      this.dialogVisible = true;
    },
    butPassword: function butPassword() {
      var _this2 = this;

      var parm = {
        oldPassword: Base64.encode(this.passwordArry.oldPassword),
        newPassword: Base64.encode(this.passwordArry.newPassword)
      };
      getupdatePassword(parm).then(function (res) {
        _this2.dialogVisible = false;

        _MessageBox.alert("您的密码修改成功，请重新登录", "提示", {
          confirmButtonText: "确定",
          callback: function callback(action) {
            // localStorage.clear();
            sessionStorage.clear();

            _this2.$router.push("/");
          }
        });
      });
    },

    /**
     * @description: 弹出修改用户信息
     * @author: Xiao
     * @Date: 2019-12-31 14:13:11
     */
    changeInformation: function changeInformation() {
      this.dialogUserinformation = true;
      this.ruleForm = JSON.parse(sessionStorage.getItem("userInfo"));
    },

    /**
     * @description: 修改用户信息
     * @author: Xiao
     * @Date: 2019-12-31 14:13:11
     */
    onSubmit: function onSubmit(formName) {
      var _this3 = this;

      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.onGetClerksAdd();
        }
      });
    },
    onGetClerksAdd: function onGetClerksAdd() {
      var _this4 = this;

      getupdateUserInfo(this.ruleForm).then(function () {
        _this4.dialogUserinformation = false;

        _MessageBox.alert("您的个人个人信息修改成功，请重新登录", "提示", {
          confirmButtonText: "确定",
          callback: function callback(action) {
            //localStorage.clear();
            sessionStorage.clear();

            _this4.$router.push("/");
          }
        });
      });
    },

    /**
     * @description: 取消
     * @author: Xiao
     * @Date: 2020-01-05 14:19:52
     */
    resetForm: function resetForm(formName) {
      this.dialogUserinformation = false;
      this.$refs[formName].resetFields();
    },
    //判断设备
    isMobile: function isMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    //全屏
    screen: function screen() {
      if (this.isMobile()) {
        this.$message("您的浏览器不能全屏");
      } else {
        screenfull.toggle();
      } // //如果不允许进入全屏，发出不允许提示
      // if (!screenfull.enabled) {
      //   return false;
      // }

    }
  }
};