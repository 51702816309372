export default {
  keyList: {},
  canDoFunction: function canDoFunction() {
    var _this = this;

    var b = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.keyList[b.key] ? b.fail && b.fail() : (this.lockKey(b.key), b.success && b.success(), b.time && setTimeout(function () {
      _this.releaseKey(b.key);
    }, b.time));
  },
  releaseKey: function releaseKey(b) {
    delete this.keyList[b];
  },
  lockKey: function lockKey(b) {
    this.keyList[b] = !0;
  }
};