//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { storageSetItem, storageGetItem } from "@/utils";
import { getProductOrder as _getProductOrder } from "@/api/login";
import { analysisTime2 } from "@/utils/time.js";
export default {
  name: "LayoutAside",
  props: {
    // 边栏菜单
    menuChildren: {
      type: Array
    }
  },
  data: function data() {
    return {
      asideIndex: 0,
      asideIndex2: 0,
      subMenuChild: [],
      wareHouseName: JSON.parse(sessionStorage.getItem("userInfo")).wareHouseName
    };
  },
  watch: {
    // 监听侧边栏变化
    asideIndex: function asideIndex() {
      this.asideIndex = storageGetItem("asideIndex") ? storageGetItem("asideIndex") : 0;
      this.onJumpRoute();
    },
    // 监听菜单数组变化
    menuChildren: function menuChildren() {
      this.asideIndex = storageGetItem("headerFlag") ? 0 : storageGetItem("asideIndex") ? storageGetItem("asideIndex") : 0;
      this.subMenuChild = this.menuChildren;
      this.onJumpRoute();
    }
  },
  methods: {
    getProductOrder: function getProductOrder() {
      var _this = this;

      _getProductOrder({
        page: 1,
        pageSize: 1
      }).then(function (res) {
        if (res.status == 200) {
          // if (
          //   res.data.data[0].createTime.split(" ")[0] !=
          //     analysisTime2(new Date()) &&
          //   this.wareHouseName != "团购七仓"
          // ) {
          //   this.$message({
          //     showClose: true,
          //     message: "今日未下单，请先完成要货后再使用本功能",
          //     type: "warning",
          //   });
          //   return;
          // } else {
          _this.asideIndex = _this.asideIndex2;
          storageSetItem("asideIndex", _this.asideIndex);

          _this.onJumpRoute();

          return;
        } // }

      });
    },

    /**
     * @description: 路由跳转
     * @author: Xiao
     * @Date: 2019-12-31 11:20:30
     */
    onJumpRoute: function onJumpRoute() {
      this.$router.push({
        name: this.subMenuChild[this.asideIndex].name
      });
    },

    /**
     * @description: 路由切换
     * @author: Xiao
     * @Date: 2019-12-31 14:16:21
     */
    onChangeRoute: function onChangeRoute(i, menu) {
      if (menu.meta.title !== "秒杀仓") {
        storageSetItem("headerFlag", false);
        this.asideIndex = i;
        storageSetItem("asideIndex", this.asideIndex);
        this.onJumpRoute();
      } else {
        this.asideIndex2 = i;
        this.getProductOrder();
      }
    }
  }
};