/*
 * @Author: Xiao
 * @Date: 2019-10-27 15:07:02
 * @Description: file content
 */
var getters = {
  dataDic: function dataDic(state) {
    return state.login.dataDic;
  },
  userPermissions: function userPermissions(state) {
    return state.login.userPermissions;
  }
};
export default getters;