import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/*
 * @Author: Xiao
 * @Date: 2019-10-27 21:22:47
 * @Description: file content
 */
import axios from "axios";
import qs from "qs";
import { throwErr } from "./throwErr";
import { storageGetItem } from "@/utils/index"; // 创建axios实例

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  // api的base_url
  timeout: 20000,
  // 请求超时时间
  headers: {
    "Content-Type": "application/json",
    "user-type": "pad"
  }
}); // request拦截器

service.interceptors.request.use(function (config) {
  // 配置调试
  handleUrl(); // 处理请求参数

  handleParams(config); // 是否要更改header内容 上传文件

  if (config.isFile) {
    config.headers["Content-Type"] = "multipart/form-data";
  } // 用qs处理参数可以处理options请求（预请求），或者设置'Access-Control-MAX-AGE':'1000'


  if (config.method === "post") {
    // 设置参数拼接方式
    if (config.data && config.headers["Content-Type"] === "application/x-www-form-urlencoded") {
      config.data = qs.stringify(config.data);
    }
  } else {
    if (config.data) {
      config.url = config.url + "?" + qs.stringify(config.data);
    }
  }

  return config;
}, function (error) {
  Promise.reject(error);
}); // respone拦截器

service.interceptors.response.use(function (response) {
  // code为非2000是抛错 可结合自己业务进行修改
  if (response.status === 200) {
    var res = response.data;

    if (res.status === "200") {
      return Promise.resolve(res);
    } else {
      return throwErr(res);
    }
  } else {}
}, function (error) {
  // 断网 或者 请求超时 状态
  if (!error.response) {
    // 请求超时状态
    if (error.message.includes("timeout")) {
      _Message({
        type: "error",
        message: "请求超时，请检查网络是否连接正常"
      });
    } else {
      // 可以展示断网组件
      _Message({
        type: "error",
        message: "请求失败，请检查网络是否已连接"
      });
    }

    return Promise.reject(error);
  } else {
    // status 为500抛异常
    if (error.response.status === 500) {
      _Message({
        type: "error",
        message: error.response.data.message
      });
    }
  }

  return Promise.reject(error);
}); // 处理请求参数

function handleParams(config) {
  if (!config.data) {
    // 防止不传参数的情况下，config中没有data属性
    config["data"] = {};
  } // 登录不要token


  if (!config.noLogin) {
    config.headers["sessionId"] = JSON.parse(sessionStorage.getItem("userInfo")).sessionId;
  } // 合并请求参数


  if (config.params) {
    config.data = _objectSpread({}, config.data, {}, config.params);
  }
} // 处理调试模式下，端口问题


function handleUrl() {
  if (process.env.NODE_ENV !== "debugger") {
    return;
  }
}

export default service;