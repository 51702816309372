var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout-header__wrapper" },
    [
      _c(
        "ul",
        { staticClass: "layout-header__left" },
        [
          _c("li", { on: { click: _vm.onLogout } }, [
            _c("span", [_vm._v("退 出")])
          ]),
          _vm._v(" "),
          _vm._l(_vm.menuRoute, function(menu, i) {
            return _c(
              "li",
              {
                key: i,
                class: { active: _vm.headerIndex === i },
                on: {
                  click: function($event) {
                    return _vm.onChangeMenu(i)
                  }
                }
              },
              [_c("span", [_vm._v(_vm._s(menu.meta.title))])]
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticStyle: { "margin-right": "15vw" } }, [
        _c("p", { staticStyle: { margin: "1% 0", "font-size": "1vw" } }, [
          _vm._v(
            "\n      仓库名称：" + _vm._s(_vm.userInfo.wareHouseName) + "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticStyle: { margin: "1% 0", "font-size": "1vw" } }, [
          _vm._v(
            "\n      门店名称：" + _vm._s(_vm.userInfo.shopName) + "\n    "
          )
        ]),
        _vm._v(" "),
        _c("p", { staticStyle: { margin: "1% 0", "font-size": "1vw" } }, [
          _vm._v(
            "\n      店员信息：" + _vm._s(_vm.userInfo.userName) + "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("ul", { staticClass: "layout-header__right" }, [
        _c("li", { on: { click: _vm.screen } }, [
          _c("span", [_vm._v("全 屏")])
        ]),
        _vm._v(" "),
        _c("li", { on: { click: _vm.changeInformation } }, [
          _c("span", [_vm._v("修改信息")])
        ]),
        _vm._v(" "),
        _c("li", { on: { click: _vm.changePassword } }, [
          _c("span", [_vm._v("修改密码")])
        ]),
        _vm._v(" "),
        _c("li", { on: { click: _vm.onRefresh } }, [
          _c("span", [_vm._v("刷 新")])
        ])
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            title: "修改密码",
            width: "94%",
            top: "80px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "passwordArry", attrs: { model: _vm.passwordArry } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "原始密码" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "18", type: "password" },
                    model: {
                      value: _vm.passwordArry.oldPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.passwordArry, "oldPassword", $$v)
                      },
                      expression: "passwordArry.oldPassword"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "新密码" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "18", type: "password" },
                    model: {
                      value: _vm.passwordArry.newPassword,
                      callback: function($$v) {
                        _vm.$set(_vm.passwordArry, "newPassword", $$v)
                      },
                      expression: "passwordArry.newPassword"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { type: "success" },
                      on: { click: _vm.butPassword }
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogUserinformation,
            title: "修改用户信息",
            width: "94%",
            top: "80px"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogUserinformation = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "clerk-operate",
              attrs: { model: _vm.ruleForm, rules: _vm.rules }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "userName", label: "用户名称" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.userName,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "userName", $$v)
                      },
                      expression: "ruleForm.userName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "userIdCard", label: "用户身份证" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "18" },
                    model: {
                      value: _vm.ruleForm.userIdCard,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "userIdCard", $$v)
                      },
                      expression: "ruleForm.userIdCard"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "email", label: "用户邮箱" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.email,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "email", $$v)
                      },
                      expression: "ruleForm.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { prop: "age", label: "用户年龄" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.ruleForm.age,
                      callback: function($$v) {
                        _vm.$set(_vm.ruleForm, "age", _vm._n($$v))
                      },
                      expression: "ruleForm.age"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "用户性别" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "medium" },
                      model: {
                        value: _vm.ruleForm.sex,
                        callback: function($$v) {
                          _vm.$set(_vm.ruleForm, "sex", $$v)
                        },
                        expression: "ruleForm.sex"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: "1" } }, [_vm._v("男")]),
                      _vm._v(" "),
                      _c("el-radio", { attrs: { label: "2" } }, [_vm._v("女")])
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          return _vm.onSubmit("ruleForm")
                        }
                      }
                    },
                    [_vm._v("确定")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.resetForm("ruleForm")
                        }
                      }
                    },
                    [_vm._v("取消")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }