var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout" },
    [
      _c("layout-header", {
        staticClass: "layout-header",
        on: { onChangeMenu: _vm.onChangeMenu }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-wrapper" },
        [
          _c("layout-aside", {
            staticClass: "layout-aside",
            attrs: { menuChildren: _vm.menuChildren }
          }),
          _vm._v(" "),
          _c(
            "transition",
            { attrs: { name: "fade-transform", mode: "out-in" } },
            [_c("router-view", { staticClass: "layout-main" })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }