/*
 * @Description: 储存
 * @Author: Xiao
 * @Date: 2019-12-30 14:39:56
 * @LastEditors  : Xiao
 * @LastEditTime : 2020-01-05 19:27:24
 */
var login = {
  state: {
    // 头部导航索引
    headerIndex: 1,
    // 头部导航
    menuRoute: [],
    // 侧边栏导航索引
    activeIndex: 0,
    // 侧边栏
    menuChildren: []
  },
  mutations: {
    SET_HEADERINDEX: function SET_HEADERINDEX(state, value) {
      state.headerIndex = value;
    },
    SET_MENUROUTE: function SET_MENUROUTE(state, value) {
      state.menuRoute = value;
    },
    SET_ACTIVEINDEX: function SET_ACTIVEINDEX(state, value) {
      state.activeIndex = value;
    },
    SET_MENUCHILDREN: function SET_MENUCHILDREN(state, value) {
      state.menuChildren = value;
    }
  }
};
export default login;