/*
 * @Author: Xiao
 * @Date: 2019-10-27 15:07:02
 * @Description: file content
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import login from "./modules/login";
Vue.use(Vuex);
var store = new Vuex.Store({
  modules: {
    login: login
  },
  getters: getters
});
export default store;