/*
 * @Description: api
 * @Author: Xiao
 * @Date: 2019-12-29 23:51:57
 * @LastEditors  : Xiao
 * @LastEditTime : 2020-01-13 18:00:37
 */
import request from "@/utils/request";
import requestnd from "@/utils/requestnd"; // 登录

export function getLogin(data) {
  return request({
    url: "/pad/api/pad/shop/user/loginNew",
    method: "post",
    data: data,
    noLogin: true
  });
} //经销商登录

export function getDealerLogin(data) {
  return request({
    url: "/pad/api/pad/wxDealer/login",
    method: "post",
    data: data,
    noLogin: true
  });
} // 退出登录

export function getLogOut() {
  return request({
    url: "/pad/api/pad/shop/user/logOut",
    method: "post"
  });
} // 发送短信

export function getSendSms(data) {
  return request({
    url: "/pad/api/send/sms/send",
    method: "post",
    data: data
  });
} // 申请验证码

export function getsendRequestOrderSms(data) {
  return request({
    url: "/pad/api/send/sms/sendRequestOrderSms",
    method: "post",
    data: data
  });
} // 重置密码

export function getForgetPassword(data) {
  return request({
    url: "/pad/api/pad/shop/user/forgetPassword",
    method: "post",
    data: data
  });
} // 获取商品分类信息

export function getProductCategory(data) {
  return request({
    url: "/pad/api/pad/productCategory/gets",
    method: "post",
    data: data
  });
} // 获取商品分类列表

export function getProductList(data) {
  return request({
    url: "/pad/api/pad/product/gets",
    method: "post",
    data: data
  });
} // 获取商品分类列表（无Loding）

export function getProductListnd(data) {
  return requestnd({
    url: "/pad/api/pad/product/gets",
    method: "post",
    data: data
  });
} // 获取历史要货订单列表

export function getProductOrder(data) {
  return request({
    url: "/pad/api/pad/shop/productOrder/gets",
    method: "post",
    data: data
  });
} // 获取历史要货订单的详情（要货种类及数量，入库种类及数量）

export function getProductOrderDetails(data) {
  return request({
    url: "/pad/api/pad/shop/productOrder/get",
    method: "post",
    data: data
  });
} // 获取历史调货列表

export function getProductAllocation(data) {
  return request({
    url: "/pad/api/pad/productAllocation/gets",
    method: "post",
    data: data
  });
} // 获取历史调货清单中某条详情信息

export function getByAllocationId(data) {
  return request({
    url: "/pad/api/pad/productAllocation/getByAllocationId",
    method: "post",
    data: data
  });
} // 变更商品折扣

export function getUpdateDiscount(data) {
  return request({
    url: "/pad/api/pad/shop/productDiscount/updateDiscount",
    method: "post",
    data: data
  });
} // 存储商品购买记录

export function getSaleHistory(data) {
  return request({
    url: "/pad/api/pad/shop/saleHistory/add",
    method: "post",
    data: data
  });
} // 获取下单商品列表

export function getRequestProduct(data) {
  return request({
    url: "/pad/api/pad/product/getRequestProduct",
    method: "post",
    data: data
  });
} // 获取下单商品列表(无Loding)

export function getRequestProductnd(data) {
  return requestnd({
    url: "/pad/api/pad/product/getRequestProduct",
    method: "post",
    data: data
  });
} // 提交调货申请

export function getSubmitAllocation(data) {
  return request({
    url: "/pad/api/pad/productAllocation/submitAllocation",
    method: "post",
    data: data
  });
} // 新增要货订单

export function getProductOrderAdd(data) {
  return request({
    url: "/pad/api/pad/shop/productOrder/addOrder",
    method: "post",
    data: data
  });
} // 配送列表

export function getProductDelivery(data) {
  return request({
    url: "/pad/api/pad/shop/productDelivery/gets",
    method: "post",
    data: data
  });
} // 获取配送详情信息

export function getsByDeliveryId(data) {
  return request({
    url: "/pad/api/pad/shop/productDelivery/getsByDeliveryId",
    method: "post",
    data: data
  });
} // 确认要货订单

export function getConfirmOrder(data) {
  return request({
    url: "/pad/api/pad/shop/productDelivery/confirmOrder",
    method: "post",
    data: data
  });
} // 创建盘点记录数据

export function getInitInventory(data) {
  return request({
    url: "/pad/api/pad/shop/initInventory",
    method: "post",
    data: data
  });
} // 获取盘点记录数据

export function getInventoryList(data) {
  return request({
    url: "/pad/api/pad/shop/get",
    method: "post",
    data: data
  });
} // 保存盘点记录

export function getInventorySave(data) {
  return request({
    url: "/pad/api/pad/shop/saveInventory",
    method: "post",
    data: data
  });
} // 提交盘点记录

export function getSubmitInventory(data) {
  return request({
    url: "/pad/api/pad/shop/submitInventory",
    method: "post",
    data: data
  });
} // 清空本次盘点记录

export function getClearInventory(data) {
  return request({
    url: "/pad/api/pad/shop/clearInventory",
    method: "post",
    data: data
  });
} // 获取盘点历史列表

export function getInventoryHistory(data) {
  return request({
    url: "/pad/api/pad/shop/gets",
    method: "post",
    data: data
  });
} // 获取盘点历史列表

export function getUpdateInventoryStatus(data) {
  return request({
    url: "/pad/api/pad/shop/updateInventoryStatus",
    method: "post",
    data: data
  });
} // 获取门店店员信息列表

export function getClerks(data) {
  return request({
    url: "/pad/api/pad/shop/getClerks",
    method: "post",
    data: data
  });
} // 禁用或启用店员

export function getEnableClerk(data) {
  return request({
    url: "/pad/api/pad/shop/enableClerk",
    method: "post",
    data: data
  });
} // 添加店员

export function getClerksAdd(data) {
  return request({
    url: "/pad/api/pad/shop/addClerk",
    method: "post",
    data: data
  });
} // 店长查看门店配置信息

export function getViewShopConfig(data) {
  return request({
    url: "/pad/api/pad/shop/viewShopConfig",
    method: "post",
    data: data
  });
} // 店长修改门店配置信息

export function getUpdateShopConfig(data) {
  return request({
    url: "/pad/api/pad/shop/updateShopConfig",
    method: "post",
    data: data
  });
} // 获取门店积分

export function getMoney(data) {
  return requestnd({
    url: "/pad/api/pad/shop/getMoney",
    method: "post",
    data: data
  });
} // 入库记录查询

export function getProductPutStore(data) {
  return request({
    url: "/pad/api/pad/shop/productPutStore/gets",
    method: "post",
    data: data
  });
} // 查看入库详细记录

export function getProductPutStoreDetails(data) {
  return request({
    url: "/pad/api/pad/shop/productPutStore/getItems",
    method: "post",
    data: data
  });
} // 转换单位下拉

export function getConvertGroupUnit(data) {
  return request({
    url: "/pad/api/pad/product/getConvertGroupUnit",
    method: "post",
    data: data
  });
} // 库存商品转换单位

export function getConvertStork(data) {
  return request({
    url: "/pad/api/pad/product/convertStork",
    method: "post",
    data: data
  });
} // 部分折扣

export function getPartialDiscount(data) {
  return request({
    url: "/pad/api/pad/shop/productDiscount/partialDiscount",
    method: "post",
    data: data
  });
} // 自有商品列表获取

export function getOwnProduct(data) {
  return requestnd({
    url: "/pad/api/pad/shop/ownProduct/gets",
    method: "post",
    data: data
  });
} // 自有商品保存或修改

export function getSaveOrUpdate(data) {
  return request({
    url: "/pad/api/pad/shop/ownProduct/saveOrUpdate",
    method: "post",
    data: data
  });
} // 自有商品备注

export function getExamine(data) {
  return request({
    url: "/pad/api/pad/shop/examine/get",
    method: "post",
    data: data
  });
} // 自有商品启用/禁用

export function getUpdateStatus(data) {
  return request({
    url: "/pad/api/pad/shop/ownProduct/updateStatus",
    method: "post",
    data: data
  });
} // 下拉获取基本单位

export function getSelectBaseUnit(data) {
  return request({
    url: "/pad/api/pad/baseUnit/selectBaseUnit",
    method: "post",
    data: data
  });
} // 上传图片

export function getUploadFile(data, type) {
  return request({
    url: "/pad/api/pad/uploadFile/dealupload/" + type,
    method: "post",
    data: data,
    isFile: true
  });
} // 获取销售记录列表

export function getSaleHistoryList(data) {
  return request({
    url: "/pad/api/pad/shop/saleHistory/gets",
    method: "post",
    data: data
  });
} // 销售历史详情

export function getSaleHistoryItems(data) {
  return request({
    url: "/pad/api/pad/shop/saleHistory/getItems",
    method: "post",
    data: data
  });
} // 新增异常商品（确认收货时） 确认收货时传errorType=3 targetItemId为配送子项id targetId为配送id

export function getAdd(data) {
  return request({
    url: "/pad/api/pad/special/add",
    method: "post",
    data: data
  });
} //获取报价配置时间

export function getConfig(data) {
  return request({
    url: "/paipai/api/pp/config/getConfig/" + data,
    method: "get"
  });
} //获取库存明细信息

export function getStockInfoId(data) {
  return request({
    url: "/pad/api/pad/shop/stock/getStockInfo/" + data,
    method: "get"
  });
} // 获取异常子项信息详细

export function getSpecialItemInfo(data) {
  return request({
    url: "/pad/api/pad/special/getSpecialItemInfo",
    method: "post",
    data: data
  });
} // 获取异常子项信息详细同时查询审核信息

export function getSpecialItemAndExamineInfo(data) {
  return request({
    url: "/pad/api/pad/special/getSpecialItemAndExamineInfo",
    method: "post",
    data: data
  });
} // 修改自有商品上架状态

export function getupdateUpperShelf(data) {
  return request({
    url: "/pad/api/pad/shop/ownProduct/updateUpperShelf",
    method: "post",
    data: data
  });
} // 导出发送销售记录excel到指定邮箱

export function getexportSendEmailSale(data) {
  return request({
    url: "/pad/api/pad/shop/saleHistory/exportSendEmailSale",
    method: "post",
    data: data
  });
} // 修改密码

export function getupdatePassword(data) {
  return request({
    url: "/pad/api/pad/user/updatePassword",
    method: "post",
    data: data
  });
} // 修改用户信息

export function getupdateUserInfo(data) {
  return request({
    url: "/pad/api/pad/user/updateUserInfo",
    method: "post",
    data: data
  });
} // 获取订单列表

export function getsmallRoutine(data) {
  return request({
    url: "/pad/api/smallRoutine/order/gets",
    method: "post",
    data: data
  });
} // 获取订单明细信息

export function getOrderInfo(data) {
  return request({
    url: "/pad/api/smallRoutine/order/getOrderInfo",
    method: "post",
    data: data
  });
} // 订单已确认

export function getorderReady(data) {
  return request({
    url: "/pad/api/smallRoutine/order/orderReady",
    method: "post",
    data: data
  });
} // 门店小程序自提订单用户已提货

export function getpadOrderComplete(data) {
  return request({
    url: "/pad/api/smallRoutine/order/padOrderComplete",
    method: "post",
    data: data
  });
} // 移除用户

export function getdeleteUserInfo(data) {
  return request({
    url: "/pad/api/pad/user/deleteUserInfo",
    method: "post",
    data: data
  });
} //忘记密码登入

export function getupdatePassAfterLogin(data) {
  return request({
    url: "/pad/api/pad/shop/user/updatePassAfterLogin",
    method: "post",
    data: data
  });
} //退单

export function getsaleHistoryrefund(data) {
  return request({
    url: "/pad/api/pad/shop/saleHistory/refund",
    method: "post",
    data: data
  });
} //添加购物车

export function insertOrUpdateUserCartInfo(data) {
  return request({
    url: "/pad/api/pad/shop/userCart/insertOrUpdateUserCartInfo",
    method: "post",
    data: data
  });
} //获取用户购物车信息

export function getUserShopVehicle(data) {
  return request({
    url: "/pad/api/pad/shop/userCart/gets",
    method: "post",
    data: data
  });
} //清空用户购物车信息

export function clearAllCarts(data) {
  return request({
    url: "/pad/api/pad/shop/userCart/clearAllCarts",
    method: "post",
    data: data
  });
} //删除指定购物车记录

export function deleteUserCartInfo(data) {
  return request({
    url: "/pad/api/pad/shop/userCart/deleteUserCartInfo",
    method: "post",
    data: data
  });
} //修改库存小程序上架状态

export function updateViewStatus(data) {
  return request({
    url: "/pad/api/pad/product/updateViewStatus",
    method: "post",
    data: data
  });
} //获取积分充值按钮字典

export function getRechargeShopMoneyConfig(data) {
  return request({
    url: "/pad/api/pad/shop/getRechargeShopMoneyConfig",
    method: "post",
    data: data
  });
} //创建积分充值订单

export function addRechargeShopMoney(data) {
  return request({
    url: "/pad/api/pad/shop/addRechargeShopMoney",
    method: "post",
    data: data
  });
} //用户订单支付状态

export function getPayStatus(data) {
  return requestnd({
    url: "/pad/api/shop/userOrder/getPayStatus",
    method: "post",
    data: data
  });
} //转换库存

export function zhconvertStork(data) {
  return requestnd({
    url: "/pad/api/pad/shop/stock/convertStork",
    method: "post",
    data: data
  });
} //修改库存信息

export function updateStockInfo(data) {
  return requestnd({
    url: "/pad/api/pad/shop/stock/updateStockInfo",
    method: "post",
    data: data
  });
} //查看下载地址

export function fileUploadLog(data) {
  return request({
    url: "/tranceAbility/api/tranceAbility/fileUploadLog/get/" + data,
    method: "get"
  });
} //获取门店的设备信息

export function getShopPondEquipmentByShopId(data) {
  return requestnd({
    url: "/pad/api/pad/shop/fishPondEquipment/getShopPondEquipmentByShopId",
    method: "post",
    data: data
  });
} //创建门店设备支付订单

export function createShopPondEquipmentOrder(data) {
  return requestnd({
    url: "/pad/api/pad/shop/fishPondEquipment/createShopPondEquipmentOrder",
    method: "post",
    data: data
  });
} //鱼池设备订单详细信息 *

export function getShopPondOrderItems(data) {
  return requestnd({
    url: "/pad/api/pad/shop/fishPondEquipment/getShopPondOrderItems",
    method: "post",
    data: data
  });
} //获取门店鱼池设备绑定的关联续费订单列表 *

export function getShopPondOrders(data) {
  return requestnd({
    url: "/pad/api/pad/shop/fishPondEquipment/getShopPondOrders",
    method: "post",
    data: data
  });
} //通过鱼池设备订单获取鱼池设备订单列表

export function getShopPondOrdersByEqId(data) {
  return requestnd({
    url: "/pad/api/pad/shop/fishPondEquipment/getShopPondOrdersByEqId",
    method: "post",
    data: data
  });
} //门店库存商品改价

export function updateSalePrice(data) {
  return requestnd({
    url: "/api/shop/shopPrice/updateSalePrice",
    method: "post",
    data: data
  });
} //判断鱼池续费问题

export function getNoRenewalFishPond(data) {
  return requestnd({
    url: "/pad/api/pad/shop/fishPondEquipment/getNoRenewalFishPond",
    method: "post",
    data: data
  });
} //获取销售购物车列表

export function getSaleHistoryCarts(data) {
  return requestnd({
    url: "/pad/api/pad/shop/userCart/getSaleHistoryCarts",
    method: "post",
    data: data
  });
} //获取用户要货购物车信息

export function getRequestCarts(data) {
  return requestnd({
    url: "/pad/api/pad/shop/userCart/getRequestCarts",
    method: "post",
    data: data
  });
} //补打发票返回信息

export function getReInvoiceInfo(data) {
  return requestnd({
    url: "/pad/api/pad/shop/saleHistory/getReInvoiceInfo",
    method: "post",
    data: data
  });
} //用户充值订单列表

export function getIntegralhistory(data) {
  return requestnd({
    url: "/pad/api/shop/userOrder/gets",
    method: "post",
    data: data
  });
} //销售去支付生成预支付单

export function advancePayment(data) {
  return requestnd({
    url: "/pad/api/pad/shop/saleHistory/advancePayment",
    method: "post",
    data: data
  });
} //付款码支付

export function createSweepPayNew(data) {
  return requestnd({
    url: "/pad/api/pad/shop/saleHistory/createSweepPayNew",
    method: "post",
    data: data
  });
} //获取销售状态信息

export function queryPayInfo(data) {
  return requestnd({
    url: "/pad/api/pad/shop/saleHistory/queryPayInfo",
    method: "post",
    data: data
  });
} //获取积分变更历史

export function getsMoneyHistory(data) {
  return requestnd({
    url: "/pad/api/pad/shop/getsMoneyHistory",
    method: "post",
    data: data
  });
} //付款码支付关闭订单

export function closeOrder(data) {
  return requestnd({
    url: "/pad/api/pad/shop/saleHistory/closeOrder",
    method: "post",
    data: data
  });
} //Pad端图信息

export function getCharts(data) {
  return requestnd({
    url: "/pad/api/pad/priceChangeStatistics/getCharts",
    method: "post",
    data: data
  });
} //导出订单

export function exportOrderItems(data) {
  return requestnd({
    url: "/pad/api/pad/shop/productOrder/exportOrderItems",
    method: "post",
    data: data
  });
} //导出订单信息

export function exportOrders(data) {
  return requestnd({
    url: "/pad/api/pad/shop/productOrder/exportOrders",
    method: "post",
    data: data
  });
} //导出库存明细

export function exportShopStockProduct(data) {
  return requestnd({
    url: "/pad/api/pad/shop/stock/exportShopStockProduct",
    method: "post",
    data: data
  });
} //导出盘点记录

export function exportInventory(data) {
  return requestnd({
    url: "/pad/api/pad/shop/exportInventory/" + data,
    method: "post"
  });
} //获取门店用户绑定设备

export function getShopUserDevice(data) {
  return requestnd({
    url: "/pad/api/pad/shop/getShopUserDevice",
    method: "post",
    data: data
  });
} //设备绑定用户

export function deviceBindUser(data) {
  return requestnd({
    url: "/pad/api/pad/shop/deviceBindUser/" + data,
    method: "post"
  });
} //修改购物车折扣信息（整体打折)

export function updateCartDiscount(data) {
  return requestnd({
    url: "/pad/api/pad/shop/userCart/updateCartDiscount",
    method: "post",
    data: data
  });
} //下载文件列表

export function fileAttach(data) {
  return requestnd({
    url: "/tranceAbility/api/tranceAbility/fileAttach/gets",
    method: "post",
    data: data,
    headers: {
      sessionId: JSON.parse(sessionStorage.getItem("userInfo")).sessionId,
      "Content-Type": "application/json"
    }
  });
} //获取阿里云数据

export function getOssStsToken(data) {
  return requestnd({
    url: "/tranceAbility/api/tranceAbility/upload/getOssStsToken",
    method: "post",
    data: data,
    headers: {
      sessionId: JSON.parse(sessionStorage.getItem("userInfo")).sessionId,
      "Content-Type": "application/json"
    }
  });
} //视频上传回调地址

export function wxSaveVideoUploadLog(data) {
  return requestnd({
    url: "/tranceAbility/api/tranceAbility/fileUploadLog/wxSaveVideoUploadLog",
    method: "post",
    data: data,
    headers: {
      sessionId: JSON.parse(sessionStorage.getItem("userInfo")).sessionId,
      "Content-Type": "application/json"
    }
  });
}