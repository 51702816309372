import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "App",
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  data: function data() {
    return {
      width: "",
      height: "",
      isRouterAlive: true
    };
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      // 监听resize方法
      window.addEventListener("resize", _this.renderResize, false);
    });
  },
  methods: {
    /**
     * @Author: Xiao
     * @Descripttion: 判断横竖屏
     * @Date: 2020-01-06 18:32:44
     */
    renderResize: function renderResize() {
      var _this2 = this;

      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;

      if (this.width < this.height) {
        _MessageBox.confirm("请使用横屏模式浏览？", "提示信息", {
          distinguishCancelAndClose: true,
          confirmButtonText: "确 定",
          showClose: false,
          showCancelButton: false,
          closeOnClickModal: false,
          closeOnPressEscape: false,
          beforeClose: function beforeClose(action, instance, done) {
            if (action === "confirm") {
              // instance
              if (_this2.width > _this2.height) {
                done();
              }
            }
          }
        });
      }
    },

    /**
     * @description: 刷新页面
     * @author: Xiao
     * @Date: 2020-01-11 18:11:52
     */
    reload: function reload() {
      var _this3 = this;

      //console.log(1);
      this.isRouterAlive = false;
      this.$nextTick(function () {
        _this3.isRouterAlive = true;
      });
    }
  }
};

window.scanResult = function (data) {
  window.value = data;
  sessionStorage.setItem("fkcode", data);
};