// components/index.js
import Vue from 'vue'; // 自动加载 global 目录下的 .js 结尾的文件

var componentsContext = require.context('./global', true, /\.js$/);

componentsContext.keys().forEach(function (component) {
  var componentConfig = componentsContext(component);
  /**
   * 兼容 import export 和 require module.export 两种规范
   */

  var ctrl = componentConfig.default || componentConfig;
  Vue.component(ctrl.name, ctrl);
});