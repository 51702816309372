function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

/*
 * @Description: 
 * @Author: Xiao
 * @Date: 2019-12-29 23:51:57
 * @LastEditors  : Xiao
 * @LastEditTime : 2020-01-02 18:56:04
 */
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
var routes = [];

var routerContext = require.context('./', true, /index\.js/);

routerContext.keys().forEach(function (route) {
  // 如果是根目录的index.js，不处理
  if (route.startsWith('./index')) {
    return;
  }

  var routerModule = routerContext(route);
  /**
   * 兼容 import export 和 require module.export 两种规范
   */

  routes = [].concat(_toConsumableArray(routes), _toConsumableArray(routerModule.default || routerModule));
});
var rotuers = new Router({
  // mode: 'history', //后端支持可开
  routes: routes
}); // 路由守卫，判断是否sessionStorage里面有内容，无内容直接跳登录页

rotuers.beforeEach(function (to, from, next) {
  var user = sessionStorage.getItem('userInfo');

  if (user || to.name == 'login') {
    next();
  } else {
    next('/');
  }
});
/** 2019/2/17
 * @Author: Xiao
 * @Desc: 哈希路由时，404页面要放最后
 */

rotuers.addRoutes([{
  path: '*',
  name: '404',
  redirect: '/404'
}]);
export default rotuers;