/*
 * @Description: 配置
 * @Author: Xiao
 * @Date: 2020-01-07 15:22:20
 * @LastEditors  : Xiao
 * @LastEditTime : 2020-01-11 15:09:04
 */
var plugins = [];

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(function (requireContext) {
    plugins.push(requireContext);
    return requireContext;
  });
};

var req = require.context("./", false, /\.js$/);

requireAll(req);
plugins.forEach(function (pluginPath) {
  if (pluginPath !== "./index.js") {
    require("".concat(pluginPath));
  }
});