/*
 * @Description: 路由配置 登录
 * @Author: Xiao
 * @Date: 2019-12-29 23:51:57
 * @LastEditors  : Xiao
 * @LastEditTime : 2019-12-31 10:04:58
 */
export default [{
  path: '/',
  name: 'login',
  component: function component() {
    return import('@/views/login/index.vue');
  },
  meta: {
    title: '登录'
  }
}];