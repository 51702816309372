/*
 * @Description: 404
 * @Author: Xiao
 * @Date: 2019-12-29 23:51:57
 * @LastEditors  : Xiao
 * @LastEditTime : 2019-12-30 14:40:54
 */
export default [{
  path: '/404',
  component: function component() {
    return import('@/views/error_page/404');
  }
}];