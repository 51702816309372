/*
 * @Author: Xiao
 * @Date: 2019-10-27 15:07:02
 * @Description: file content
 */
import Vue from "vue";
import "normalize.css/normalize.css";
import "./plugins";
import App from "./App.vue";
import router from "./router";
import store from "./store"; // import "./utils/solution";
// import './utils/vue_bind'

import "./components/index";
import "@/assets/styles/common.scss";
import VuePageStack from "vue-page-stack";
import VueCoreVideoPlayer from "vue-core-video-player";
import { HappyScroll } from "vue-happy-scroll";
import "vue-happy-scroll/docs/happy-scroll.css";
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts; // 调试
// import Vconsole from "vconsole";
// let vConsole = new Vconsole();
// export default vConsole;

import jiuaiDebounce from "@/utils/jiuai-debounce.js";
Vue.prototype.$jiuaiDebounce = jiuaiDebounce; // vue-router is necessary

Vue.use(VueCoreVideoPlayer);
Vue.use(VuePageStack, {
  router: router
});
Vue.component("happy-scroll", HappyScroll);
Vue.config.productionTip = false; // 扫码机全局方法

Vue.prototype.scanResult = function () {
  window.scanResult = function (data) {
    alert(data);
    window.value = data;
  };
};

new Vue({
  store: store,
  router: router,
  render: function render(h) {
    return h(App);
  }
}).$mount("#app"); // (function () {
//   var src = '//cdn.jsdelivr.net/npm/eruda';
//   // if (!/eruda=true/.test(window.location) && localStorage.getItem('active-eruda') != 'true') return;
//   document.write('<scr' + 'ipt src="' + src + '"></scr' + 'ipt>');
//   document.write('<scr' + 'ipt>eruda.init();</scr' + 'ipt>');
// })();