var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "layout-aside__wrapper" },
    _vm._l(_vm.menuChildren, function(menu, i) {
      return _c(
        "li",
        {
          key: i,
          class: { active: _vm.asideIndex === i },
          on: {
            click: function($event) {
              return _vm.onChangeRoute(i, menu)
            }
          }
        },
        [
          _c("i", { class: menu.iconClass }),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(menu.meta.title))])
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }