function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

/*
 * @Author: Xiao
 * @Date: 2019-10-27 15:07:02
 * @Description: file content
 */

/* eslint-disable */
// 生成随机字符串
export function random_str() {
  var len = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 32;
  var $chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  var maxPos = $chars.length;
  var str = "";

  for (var i = 0; i < len; i++) {
    str += $chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return str;
} // 深拷贝

function typeOf(obj) {
  var toString = Object.prototype.toString;
  var map = {
    "[object Boolean]": "boolean",
    "[object Number]": "number",
    "[object String]": "string",
    "[object Function]": "function",
    "[object Array]": "array",
    "[object Date]": "date",
    "[object RegExp]": "regExp",
    "[object Undefined]": "undefined",
    "[object Null]": "null",
    "[object Object]": "object"
  };
  return map[toString.call(obj)];
} // deepCopy


export function deepCopy(data) {
  var t = typeOf(data);
  var o;

  if (t === "array") {
    o = [];
  } else if (t === "object") {
    o = {};
  } else {
    return data;
  }

  if (t === "array") {
    for (var i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]));
    }
  } else if (t === "object") {
    for (var _i in data) {
      o[_i] = deepCopy(data[_i]);
    }
  }

  return o;
} // 金额后面添0（0 => 0.00）

export function parseMoney(s, n) {
  if (!s) return 0;
  n = n > 0 && n <= 20 ? n : 2;
  s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
  var l = s.split(".")[0].split("").reverse();
  var r = s.split(".")[1];
  var t = "";

  for (var i = 0; i < l.length; i++) {
    t += l[i] + ((i + 1) % 3 === 0 && i + 1 !== l.length ? "," : "");
  }

  return t.split("").reverse().join("") + "." + r;
}
export function parseData(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }

  var format = cFormat || "{y}-{m}-{d}";
  var date;

  if (_typeof(time) === "object") {
    date = time;
  } else {
    if (("" + time).length === 10) time = parseInt(time) * 1000;

    if (time && time.replace) {
      time = time.replace(/-/g, "/");
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key];
    if (key === "a") return ["一", "二", "三", "四", "五", "六", "日"][value - 1];

    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }

    return value || 0;
  });
  return time_str;
} // 返回字符串后多少位 默认返回后10位

export function substringLast(str, len) {
  var strLen = len || 10;

  if (str) {
    var num = str.length;

    if (num - strLen <= 0) {
      return str;
    } else {
      return str.substring(num - strLen);
    }
  } else {
    return "";
  }
} // 缓存计算结果，第二次调用的时候直接读取缓存中的内容

export function cached(fn) {
  var cache = Object.create(null);
  return function cachedFn(str) {
    if (!cache[str]) {
      cache[str] = fn(str);
    }

    return cache[str];
  };
}
export function isOBJByType(o, type) {
  return Object.prototype.toString.call(o) === "[object " + (type || "Object") + "]";
} // localStorage.setItem

export function storageSetItem(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
} // localStorage.getItem

export function storageGetItem(key) {
  return JSON.parse(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key)) : false;
} // localStorage.removeItem

export function storageRemoveItem(key) {
  if (storageGetItem(key)) {
    localStorage.removeItem(key);
  } else {
    return false;
  }
} // 加

export var sum = function sum(num1, num2) {
  var exp_1, exp_2, nums;

  try {
    exp_1 = num1.toString().split(".")[1].length;
  } catch (_unused) {
    exp_1 = 0;
  }

  try {
    exp_2 = num2.toString().split(".")[1].length;
  } catch (_unused2) {
    exp_2 = 0;
  }

  nums = Math.max(exp_1, exp_2);
  return (num1 * Math.pow(10, nums) + num2 * Math.pow(10, nums)) / Math.pow(10, nums);
}; // 减

export var subTc = function subTc(num1, num2) {
  var nums, exp_1, exp_2;
  var precision; // 精度

  try {
    exp_1 = num1.toString().split(".")[1].length;
  } catch (e) {
    exp_1 = 0;
  }

  try {
    exp_2 = num2.toString().split(".")[1].length;
  } catch (e) {
    exp_2 = 0;
  }

  nums = Math.pow(10, Math.max(exp_1, exp_2));
  precision = exp_1 >= exp_2 ? exp_1 : exp_2;
  return ((num1 * nums - num2 * nums) / nums).toFixed(precision);
}; //乘

export var mul = function mul(num1, num2) {
  var nums = 0;

  try {
    nums += num1.toString().split(".")[1].length;
  } catch (e) {}

  try {
    nums += num2.toString().split(".")[1].length;
  } catch (e) {}

  return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10, nums);
};