import { render, staticRenderFns } from "./index.vue?vue&type=template&id=586ccef3&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=586ccef3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "586ccef3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\daima\\babaili\\babailipad-end\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('586ccef3')) {
      api.createRecord('586ccef3', component.options)
    } else {
      api.reload('586ccef3', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=586ccef3&scoped=true&", function () {
      api.rerender('586ccef3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/layout/layout-aside/index.vue"
export default component.exports