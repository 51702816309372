/*
 * @Description: 路由配置
 * @Author: Xiao
 * @Date: 2019-12-29 23:51:57
 * @LastEditors  : Xiao
 * @LastEditTime : 2020-01-13 15:24:56
 */
import Layout from "@/views/layout";
export default [// {
//       path: "/logout",
//       name: "logout",
//       reject: "/",
//       meta: { title: "登出" },
//       iconClass: "el-icon-circle-close",
//       children: []
//   },
{
  path: "/commodity",
  name: "commodity",
  component: Layout,
  meta: {
    title: "要货"
  },
  iconClass: "el-icon-truck",
  children: [{
    path: "/commodity/index",
    name: "commodity-index",
    component: function component() {
      return import("@/views/commodity/index");
    },
    meta: {
      title: "要货"
    },
    iconClass: "el-icon-truck",
    children: []
  }, {
    path: "/commodity/commodity-auction",
    name: "commodity-auction",
    component: function component() {
      return import("@/views/commodity/commodity-auction/index");
    },
    meta: {
      title: "秒杀仓"
    },
    iconClass: "el-icon-truck",
    children: []
  }, {
    path: "/commodity/commodity-history",
    name: "commodity-history",
    component: function component() {
      return import("@/views/commodity/commodity-history/index");
    },
    meta: {
      title: "历史要货"
    },
    iconClass: "el-icon-truck",
    children: []
  }, // {
  //   path: "/commodity/commodity-deploy",
  //   name: "commodity-deploy",
  //   component: () => import("@/views/commodity/commodity-deploy/index"),
  //   meta: { title: "临时调配" },
  //   iconClass: "el-icon-truck",
  //   children: []
  // },
  {
    path: "/commodity/commodity-allocation/",
    name: "commodity-allocation",
    component: function component() {
      return import("@/views/commodity/commodity-allocation/index");
    },
    meta: {
      title: "调配列表"
    },
    iconClass: "el-icon-truck",
    children: []
  }, {
    path: "/commodity/commodity-logistics",
    name: "commodity-logistics",
    component: function component() {
      return import("@/views/commodity/commodity-logistics/index");
    },
    meta: {
      title: "物流信息"
    },
    iconClass: "el-icon-truck",
    children: []
  }]
}, // {
//   path: "/warehouse",
//   name: "warehouse",
//   component: Layout,
//   meta: { title: "入库" },
//   iconClass: "el-icon-document-add",
//   children: [
//     {
//       path: "/warehouse/warehouse-inventory",
//       name: "warehouse-inventory",
//       component: () => import("@/views/warehouse/warehouse-inventory/index"),
//       meta: { title: "入库记录" },
//       iconClass: "el-icon-document-add",
//       children: []
//     },
//     {
//       path: '/warehouse/index',
//       name: 'warehouse-index',
//       component: () =>
//         import('@/views/warehouse/index'),
//       meta: { title: '确认入库' },
//       iconClass: 'el-icon-document-add',
//       children: []
//     }
//   ]
// },
// {
//   path: "/inventory",
//   name: "inventory",
//   component: Layout,
//   meta: { title: "盘点" },
//   iconClass: "el-icon-document-remove",
//   children: [
//   ]
// },
{
  path: "/store",
  name: "store",
  component: Layout,
  meta: {
    title: "管理"
  },
  iconClass: "el-icon-s-home",
  children: [{
    path: "/store/index",
    name: "store-index",
    component: function component() {
      return import("@/views/store/index");
    },
    meta: {
      title: "管理"
    },
    iconClass: "el-icon-s-home",
    children: []
  }, {
    path: "/store/store-clerk",
    name: "store-clerk",
    component: function component() {
      return import("@/views/store/store-clerk/index");
    },
    meta: {
      title: "店员信息"
    },
    iconClass: "el-icon-s-home",
    children: []
  }, {
    path: "/store/store-recharge",
    name: "store-recharge",
    component: function component() {
      return import("@/views/store/store-recharge/index");
    },
    meta: {
      title: "充值"
    },
    iconClass: "el-icon-s-finance",
    children: []
  }, {
    path: "/store/store-integralhistory",
    name: "store-integralhistory",
    component: function component() {
      return import("@/views/store/store-integralhistory/index");
    },
    meta: {
      title: "充值历史"
    },
    iconClass: "el-icon-s-finance",
    children: []
  }, {
    path: "/store/store-Integralistory",
    name: "store-Integralistory",
    component: function component() {
      return import("@/views/store/store-Integralistory/index");
    },
    meta: {
      title: "历史积分"
    },
    iconClass: "el-icon-s-finance",
    children: []
  }, {
    path: "/store/store-download",
    name: "store-download",
    component: function component() {
      return import("@/views/store/store-download/index");
    },
    meta: {
      title: "文件下载"
    },
    iconClass: "el-icon-download",
    children: []
  }]
}, {
  path: "/fishpond",
  name: "fishpond",
  component: Layout,
  meta: {
    title: "设备管理"
  },
  iconClass: "el-icon-takeaway-box",
  children: [{
    path: "/fishpond/index",
    name: "fishpond-index",
    component: function component() {
      return import("@/views/fishpond/index");
    },
    meta: {
      title: "续费管理"
    },
    iconClass: "el-icon-s-home",
    children: []
  }, {
    path: "/fishpond/printer",
    name: "fishpond-printer",
    component: function component() {
      return import("@/views/fishpond/printer");
    },
    meta: {
      title: "打印管理"
    },
    iconClass: "el-icon-s-home",
    children: []
  }, {
    path: "/fishpond/balance",
    name: "fishpond-balance",
    component: function component() {
      return import("@/views/fishpond/balance");
    },
    meta: {
      title: "秤管理"
    },
    iconClass: "el-icon-s-home",
    children: []
  }]
}, {
  path: "/home",
  name: "home",
  component: Layout,
  meta: {
    title: "销售"
  },
  iconClass: "el-icon-shopping-bag-1",
  children: [{
    path: "/home-sale",
    name: "home-sale",
    component: function component() {
      return import("@/views/home/index");
    },
    meta: {
      title: "线下销售"
    },
    iconClass: "el-icon-sold-out",
    children: []
  }, {
    path: "/home-discount",
    name: "home-discount",
    component: function component() {
      return import("@/views/home/home-discount/index");
    },
    meta: {
      title: "库存"
    },
    iconClass: "el-icon-discount",
    children: []
  }, // {
  //   path: "/home-online",
  //   name: "home-online",
  //   component: () => import("@/views/home/home-online/index"),
  //   meta: { title: "线上订单" },
  //   iconClass: "el-icon-discount",
  //   children: []
  // },
  {
    path: "/store/store-commodity",
    name: "store-commodity",
    component: function component() {
      return import("@/views/store/store-commodity/index");
    },
    meta: {
      title: "商品自营"
    },
    iconClass: "el-icon-s-home",
    children: []
  }, {
    path: "/store/store-sale",
    name: "store-sale",
    component: function component() {
      return import("@/views/store/store-sale/index");
    },
    meta: {
      title: "历史销售"
    },
    iconClass: "el-icon-s-home",
    children: []
  }, {
    path: "/inventory/index",
    name: "inventory-index",
    component: function component() {
      return import("@/views/inventory/index");
    },
    meta: {
      title: "盘点"
    },
    iconClass: "el-icon-document-remove",
    children: []
  }, {
    path: "/inventory/inventory-history",
    name: "inventory-history",
    component: function component() {
      return import("@/views/inventory/inventory-history/index");
    },
    meta: {
      title: "盘点历史"
    },
    iconClass: "el-icon-document-remove",
    children: []
  }]
}];