//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import LayoutAside from "./layout-aside/index";
import LayoutHeader from "./layout-header/index"; // import { storageSetItem } from "@/utils";

export default {
  name: "Layout",
  components: {
    LayoutAside: LayoutAside,
    LayoutHeader: LayoutHeader
  },
  data: function data() {
    return {
      menuChildren: []
    };
  },
  methods: {
    // 父子组件传值
    onChangeMenu: function onChangeMenu(data) {
      this.menuChildren = data;
    }
  }
};