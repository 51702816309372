/**
* @Author: Xiao
* @Descripttion: 时间解析
* @Date: 2020-01-03 14:43:56
*/
function analysisTime(row) {
  var date = new Date(row);
  var yy = date.getFullYear();
  if (yy < 1900) yy = yy + 1900;
  var MM = date.getMonth() + 1;
  if (MM < 10) MM = "0" + MM;
  var dd = date.getDate();
  if (dd < 10) dd = "0" + dd;
  var hh = date.getHours();
  if (hh < 10) hh = "0" + hh;
  var mm = date.getMinutes();
  if (mm < 10) mm = "0" + mm;
  var ss = date.getSeconds();
  if (ss < 10) ss = "0" + ss;
  return yy + "-" + MM + "-" + dd + " " + hh + ":" + mm + ":" + ss;
}

function analysisTime2(row) {
  var date = new Date(row);
  var yy = date.getFullYear();
  if (yy < 1900) yy = yy + 1900;
  var MM = date.getMonth() + 1;
  if (MM < 10) MM = "0" + MM;
  var dd = date.getDate();
  if (dd < 10) dd = "0" + dd;
  var hh = date.getHours();
  if (hh < 10) hh = "0" + hh;
  var mm = date.getMinutes();
  if (mm < 10) mm = "0" + mm;
  var ss = date.getSeconds();
  if (ss < 10) ss = "0" + ss;
  return yy + "-" + MM + "-" + dd;
}

export { analysisTime, analysisTime2 };